"use strict";

import { Toggle } from "./../../extends";
import Action from "./Action";

class Code extends Toggle {

  constructor(el) {
    super("is-open");

    this.el = el;

    this.init();
  }

  init() {
    super.init();

    this.action = new Action(
      this.el.querySelector(".m-code__action"),
      this.handleToggle.bind(this)
    );
  }
}

export default Code;
