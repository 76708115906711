"use strict";

class Close {

  constructor(el, { clickCb }) {
    this.el = el;
    this.clickCb = clickCb;

    this.initBinds();
    this.initEvents();
  }

  initBinds() {
    this.handleClick = this.handleClick.bind(this);
  }

  initEvents() {
    this.el.addEventListener("click", this.handleClick);
  }

  handleClick(e) {
    if (e) {
      e.preventDefault();
    }

    if (this.clickCb) {
      this.clickCb();
    }
  }
}

export default Close;
