"use strict";

// =============================================================================
// :: Imports
// =============================================================================
import {
  Collapse,
  Navigation,
  Video,
  Code,
  Background,
  Overlay,
  Gimmicks,
} from "./modules";

import { Document } from "./managers";

import { dom } from "./utils";

// =============================================================================
// :: Main initialise
// =============================================================================
const init = () => {
  dom.init(".js-nav", Navigation);

  dom.initAll(".js-collapse", Collapse);

  dom.initAll(".js-video", Video);

  dom.initAll(".js-code", Code);

  dom.init(".js-background", Background);

  dom.initAll(".js-overlay", Overlay);

  dom.init(".js-gimmicks", Gimmicks);
};

// =============================================================================
// :: Execute main initialise when document is ready
// =============================================================================
(() => {
	Document.handleReadyState(init);
})();
