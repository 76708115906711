"use strict";

import { Toggle } from "./../../extends";

class Hamburger extends Toggle {

  constructor(el, { clickCb }) {
    super("is-close");

    this.el = el;
    this.clickCb = clickCb;

    this.init();
    this.initEvents();
  }

  initEvents() {
    this.el.addEventListener("click", this.handleToggle.bind(this));
  }

  handleToggle(e, shouldTriggerCb = true) {
    super.handleToggle(e);

    if(this.clickCb && shouldTriggerCb) {
      this.clickCb();
    }
  }
}

export default Hamburger;
