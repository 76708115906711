"use strict";

let instance = null;

class Root {

  constructor() {
    if (!instance) {
      instance = this;
    }

    this.init();

    return instance;
  }

  init() {
    this.el = document.body;
  }

  hasClass(name) {
    return this.el.classList.contains(name);
  }

  addClass(name) {
    if (this.hasClass(name)) {
      return;
    }

    this.el.classList.add(name);
  }

  removeClass(name) {
    if (!this.hasClass(name)) {
      return;
    }

    this.el.classList.remove(name);
  }

  toggleClass(name) {
    const containsClass = this.hasClass(name);

    if (containsClass) {
      this.el.classList.remove(name);
    } else {
      this.el.classList.add(name);
    }
  }
}

export default new Root;
