const randomBetween = (min, max) => {
	return Math.floor(Math.random() * (max - min)) + min;
};

const gamble = (values) => {
  const chance = 100 / values.length;
  const roll = randomBetween(0, 100);
  const value = Math.floor(roll / chance);

  return values[value];
};

const getDistance = (x1, y1, x2, y2) => {
  const a = x1 - x2;
  const b = y1 - y2;

  return Math.sqrt((a * a) + (b * b));
};

const map = (value, low1, high1, low2, high2) => {
	return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
};

export default {
  randomBetween,
  gamble,
  getDistance,
  map,
};
