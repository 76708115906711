"use strict";

import Iframe from "./Iframe";

class Video {

  constructor(el) {
    this.el = el;

    this.init();
    this.initEvents();
  }

  init() {
    this.iframe = new Iframe(
      this.el.querySelector(".a-video__iframe")
    );

    this.handleResize();
  }

  initEvents() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  handleResize() {
    const width = this.el.getBoundingClientRect().width;

    this.iframe.handleResize(width);
  }
}

export default Video;

