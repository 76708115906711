"use strict";

class Content {

  constructor(el) {
    this.el = el;

    this.init();
  }

  init() {
    this.container = this.el.querySelector(".m-collapse__children");
    this.transition = this.initTransition();
    this.height = this.getHeight();
  }

  initTransition() {
    return window.getComputedStyle(this.el).transition;
  }

  getHeight() {
    return this.container.getBoundingClientRect().height;
  }

  handleOpen() {
    this.el.style.height = `${this.getHeight()}px`;
  }

  handleClose() {
    this.el.style.height = 0;
  }

  handleResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.el.style.transition = "none";

    this.handleOpen();

    this.resizeTimeout = setTimeout(() => {
      this.el.style.transition = this.transition;
    }, 50);
  }
}

export default Content;
