"use strict";

import { Toggle } from "./../../extends";
import { Root } from "./../../managers";
import Hamburger from "./Hamburger";
import Header from "./Header";
// import Root from "./Root";
import { dom } from "./../../utils";

class Navigation extends Toggle {

  constructor(el) {
    super("is-open");

    this.el = el;

    this.init();
    this.initEvents();
  }

  init() {
    super.init();

    this.hamburger = dom.init(".a-hamburger", Hamburger, {
      clickCb: this.handleToggle.bind(this)
    });
    this.header = dom.init(".o-header", Header);
  }

  initEvents() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  handleToggle() {
    super.handleToggle();

    this.header.handleToggle();
    Root.toggleClass("has-nav-open");
  }

  handleResize() {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = null;
    }

    this.el.classList.add("is-resizing");

    this.resizeTimeout = setTimeout(() => {
      this.el.classList.remove("is-resizing");
    }, 50);

    if (window.innerWidth >= 720 && this.isToggled) {
      this.handleReset();
    }
  }

  handleReset() {
    this.hamburger.handleToggle(null, false);

    this.handleToggle();
  }
}

export default Navigation;
