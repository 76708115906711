"use strict";

import map from "lodash.map";

// initialise dom elements as a JSClass
const initAll = (selector, jsClass, params) => {
  const els = document.querySelectorAll(selector);

	if (!els || els.length <= 0) {
		return null;
  }

  // map function used of lodash because IE11 still does not support
  // awesome functions
  return map(els, (el) => {
    return new jsClass(el, params);
  });
};

const init = (selector, jsClass, params) => {
  const el = document.querySelector(selector);

  if (!el) {
    return;
  }

  return new jsClass(el, params);
};

const body = (jsClass, params) => {
  const el = document.body;

  return new jsClass(el, params);
};

export default {
  initAll,
  init,
  body,
};
