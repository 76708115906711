"use strict";

// =============================================================================
// :: Imports
// =============================================================================
import Item from "./Item";
import { math } from "./../../utils";

// =============================================================================
// :: Definition
// =============================================================================
class Gimmicks {

  // :: Constructor
  // ===========================================================================
  constructor(el) {
    this.el = el;

    this.init();
  }

  // :: Inits
  // ===========================================================================
  init() {
    this.step = 1000;
    this.height = this.getHeight();
    this.container = this.el.querySelector(".c-gimmicks__container");

    this.items = this.initItems();
  }

  initItems() {
    const amount = Math.floor(this.height / this.step);
    let result = [];

    for (let i = 0; i < amount; i++) {
      const min = i * this.step;
      const max = min + this.step;

      const item = new Item({
        index: i,
        y: math.randomBetween(min, max),
      });

      this.container.appendChild(item.el);

      result.push(item);
    }

    return result;
  }

  // :: Helpers
  // ===========================================================================
  getHeight() {
    if (!this.el) {
      return 0;
    }

    return this.el.getBoundingClientRect().height;
  }
}

export default Gimmicks;

