"use strict";

import { Toggle } from "./../../extends";

class Header extends Toggle {

  constructor(el) {
    super("has-nav-open");

    this.el = el;

    this.init();
  }
}

export default Header;
