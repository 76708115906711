"use strict";

// =============================================================================
// :: Imports
// =============================================================================
import { math } from "./../../utils";

// =============================================================================
// :: Definition
// =============================================================================
class Gimmick {

  // :: Constructor
  // ===========================================================================
  constructor({
    index,
    y = 0,
  }) {
    this.index = index;
    this.y = y;

    this.init();
  }

  // :: Inits
  // ===========================================================================
  init() {
    this.el = this.initEl();

    this.handleSetPosX();
    this.handleSetPosY();
  }

  initEl() {
    const el = document.createElement("div");
    const number = math.randomBetween(1, 6);

    el.classList.add("c-gimmicks__item");
    el.classList.add(`c-gimmicks__item--${number}`);

    return el;
  }

  // :: Handlers
  // ===========================================================================
  handleSetPosX() {
    const isEven = this.index % 2 === 0;

    if (isEven) {
      this.el.classList.add("c-gimmicks__item--right");
    } else {
      this.el.classList.add("c-gimmicks__item--left");
    }
  }

  handleSetPosY() {
    this.el.style.top = `${this.y}px`;
  }
}

export default Gimmick;

