"use strict";

// =============================================================================
// :: Imports
// =============================================================================
import Circle from "./Circle";

// =============================================================================
// :: Definition
// =============================================================================
class Mask {

  // :: Constructor
  // ===========================================================================
  constructor(el) {
    this.el = el;

    this.init();
  }

  // :: Inits
  // ===========================================================================
  init() {
    this.circle = new Circle(this.el.querySelector("circle"));
  }

  // :: Life cycle
  // ===========================================================================
  update({
    x = 0,
    y = 0,
  }) {
    this.circle.update({ x, y });
  }
}

export default Mask;

