"use strict";

import Toggle from "./../../extends/Toggle";

import { Root } from "./../../managers";

import Triggers from "./Triggers";
import Close from "./Close";

class Overlay extends Toggle {

  constructor(el) {
    super("is-open");

    this.el = el;

    this.initBinds();
    this.init();
  }

  init() {
    super.init();

    this.id = this.getId();

    this.triggers = this.initTriggers();
    this.close = this.initClose();
  }

  initBinds() {
    this.handleToggle = this.handleToggle.bind(this);
  }

  initTriggers() {
    return new Triggers(document.querySelectorAll(`a[href="#${this.id}"]`), {
      clickCb: this.handleToggle,
    });
  }

  initClose() {
    return new Close(this.el.querySelector(".o-overlay__close"), {
      clickCb: this.handleToggle,
    });
  }

  getId() {
    return this.el.getAttribute("id");
  }

  handleEnable() {
    super.handleEnable();

    Root.addClass("has-overlay-open");
  }

  handleDisable() {
    super.handleDisable();

    Root.removeClass("has-overlay-open");
  }
}

export default Overlay;
