"use strict";

import Trigger from "./Trigger";

class Triggers {

  constructor(els, { clickCb }) {
    this.els = els;
    this.clickCb = clickCb;

    this.init();
  }

  init() {
    this.items = this.initItems();
  }

  initItems() {
    let result = [];

    for (let i = 0; i < this.els.length; i++) {
      const item = new Trigger(this.els[i], {
        clickCb: this.clickCb,
      });

      result.push(item);
    }

    return result;
  }

  getId() {
    return this.el.getAttribute("id");
  }
}

export default Triggers;
