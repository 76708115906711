"use strict";

class Iframe {

  constructor(el) {
    this.el = el;

    this.init();
  }

  init() {
    this.width = this.getWidth();
    this.height = this.getHeight();
    this.aspectRatio = this.getAspectRatio();

    this.handleResetDimensions();
  }

  getWidth() {
    return parseInt(this.el.getAttribute("width"));
  }

  getHeight() {
    return parseInt(this.el.getAttribute("height"));
  }

  getAspectRatio() {
    return parseFloat(this.height / this.width);
  }

  handleResetDimensions() {
    this.el.removeAttribute("width");
		this.el.removeAttribute("height");
  }

  handleResize(parentWidth) {
    const newHeight = parentWidth * this.aspectRatio;

    this.el.style.width = `${parentWidth}px`;
		this.el.style.height = `${newHeight}px`;
  }
}

export default Iframe;

