"use strict";

// =============================================================================
// :: Definition
// =============================================================================
class Circle {

  // :: Constructor
  // ===========================================================================
  constructor(el) {
    this.el = el;

    this.init();
  }

  // :: Inits
  // ===========================================================================
  init() {

  }

  // :: Life cycle
  // ===========================================================================
  update({
    x = 0,
    y = 0,
  }) {
    this.el.setAttribute("cx", x);
    this.el.setAttribute("cy", y);
  }
}

export default Circle;

