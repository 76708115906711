"use strict";

class Action {

  constructor(el, clickCb) {
    this.el = el;
    this.clickCb = clickCb;

    this.initEvents();
  }

  initEvents() {
    this.el.addEventListener("click", this.handleClick.bind(this));
  }

  handleClick(e) {
    if (e) {
      e.preventDefault();
    }

    if (this.clickCb) {
      this.clickCb();
    }
  }
}

export default Action;
