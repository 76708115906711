"use strict";

import { Toggle } from "./../../extends";
import Header from "./Header";
import Content from "./Content";

class Collapse extends Toggle {

  constructor(el) {
    super("is-open");

    this.el = el;

    this.init();
    this.initEvents();
  }

  init() {
    super.init();

    this.header = new Header(
      this.el.querySelector(".m-collapse__header"),
      this.handleToggle.bind(this)
    );

    this.content = new Content(
      this.el.querySelector(".m-collapse__content")
    );
  }

  initEvents() {
    window.addEventListener("resize", this.handleResize.bind(this));
  }

  handleEnable() {
    this.content.handleOpen();

    super.handleEnable();
  }

  handleDisable() {
    this.content.handleClose();

    super.handleDisable();
  }

  handleResize() {
    if (!this.isToggled) {
      return;
    }

    this.content.handleResize();
  }
}

export default Collapse;
