"use strict";

// =============================================================================
// :: Imports
// =============================================================================
import Mask from "./Mask";
import { Mouse } from "./../../managers";

// =============================================================================
// :: Definition
// =============================================================================
class Background {

  // :: Constructor
  // ===========================================================================
  constructor(el) {
    this.el = el;

    this.init();
  }

  // :: Inits
  // ===========================================================================
  init() {
    this.mask = new Mask(this.el.querySelector("#mask"));

    this.draw();
  }

  // :: Life cycle
  // ===========================================================================
  draw() {
    this.mask.update({
      x: Mouse.x,
      y: Mouse.y,
    });

    window.requestAnimationFrame(() => this.draw());
  }
}

export default Background;

